import { mapActions, mapMutations } from "vuex";
import router from "@/router";
import errors from "@/util/errors";

export default {
  methods: {
    ...mapActions({
      connectWallet: "wallet/connectWallet",
      logOut: "user/logOut",
    }),
    ...mapMutations({
      setIsEthereumDetected: "wallet/setIsEthereumDetected",
    }),
    checkEthereum() {
      const { ethereum } = window;
      return Boolean(ethereum && ethereum.isMetaMask);
    },
    checkConnectMethod(connectMethod) {
      return connectMethod || localStorage.getItem("connectionMethod");
    },
    async tryConnect(connectMethod) {
      try {
        this.setIsEthereumDetected(this.checkEthereum());
        return await this.connectWallet(this.checkConnectMethod(connectMethod));
      } catch (e) {
        if (e.message === "ethereum_not_detected") {
          window.location.href = "https://metamask.app.link/dapp/qnft.io";
          return;
        }
        if (
          e.message === "metamask_locked" &&
          router.currentRoute.name !== "SignIn"
        ) {
          await this.logOut();
          throw e;
        }
        if (e.code === -32002 && router.currentRoute.name === "SignIn") {
          e.message = errors.CONNECTION_REQUEST_ALREADY_SENT;
          throw e;
        }
      }
    },
  },
};
