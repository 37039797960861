import api from "@/api/api";
import networks from "@/util/networks";

export default {
  namespaced: true,
  state: {
    usdPrices: [],
  },
  mutations: {
    setUsdPrices(state, payload) {
      state.usdPrices = payload;
    },
  },
  actions: {
    async loadUsdPrice({ commit }) {
      const usdPrices = [];
      for (const i of networks) {
        const response = await api.getUsdPrice({
          currency: i.symbol,
        });
        usdPrices.push({
          symbol: i.symbol,
          value: response,
        });
      }
      commit("setUsdPrices", usdPrices);
    },
  },
  getters: {
    getUsdPrices: (state) => state.usdPrices,
  },
};
