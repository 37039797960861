export default {
  namespaced: true,
  state: {
    auction: null,
  },
  mutations: {
    setArtwork(state, payload) {
      state.auction = payload;
    },
  },
  getters: {
    getAuction: (state) => state.auction,
  },
};
