import router from "@/router";
import api from "@/api/api";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    isAuthorized: !!window.localStorage.getItem("token"),
    user: null,
  },
  mutations: {
    setToken(state, payload) {
      state.isAuthorized = true;
      state.token = payload;
      localStorage.setItem("token", payload);
    },
    unsetUser(state) {
      state.isAuthorized = false;
      state.token = null;
      state.user = null;
      localStorage.removeItem("token");
    },
    setUser(state, payload) {
      state.user = payload.userInfo;
      Vue.set(state.user, "address", payload.address);
      Vue.set(state.user, "nickName", payload.nickname);
      Vue.set(state.user, "id", payload.id);
    },
    updateUser(state, payload) {
      state.user[payload.property] = payload.value;
    },
  },
  actions: {
    async getUserInfo({ commit, dispatch }) {
      try {
        const user = await api.getUserInfo();
        commit("setUser", user);
      } catch {
        dispatch("logOut");
      }
    },
    async logOut({ commit }) {
      commit("unsetUser");
      if (router.currentRoute.meta.requiresAuth) {
        const language = localStorage.getItem("lang");
        await router.push(language ? `/${language}/sign-in` : "/sign-in");
      }
    },
  },
  getters: {
    getIsAuthorized: (state) => state.isAuthorized,
    getId: (state) => (state.user ? state.user.id : null),
    getNickName: (state) =>
      state.user && state.user.nickName ? state.user.nickName : "",
    getAddress: (state) => (state.user ? state.user.address : null),
    getName: (state) => (state.user ? state.user.name : null),
    getSurname: (state) => (state.user ? state.user.surname : null),
    getFacebook: (state) => (state.user ? state.user.facebook : null),
    getTwitter: (state) => (state.user ? state.user.twitter : null),
    getInstagram: (state) => (state.user ? state.user.instagram : null),
    getDescription: (state) => (state.user ? state.user.description : null),
    getEmail: (state) => (state.user ? state.user.email : null),
    getWebsite: (state) => (state.user ? state.user.website : null),
    getDiscord: (state) => (state.user ? state.user.discord : null),
    getAvatar: (state) =>
      state.user
        ? state.user.avatarUrl ||
          "https://avatars.dicebear.com/api/identicon/1.svg"
        : "https://avatars.dicebear.com/api/identicon/1.svg",
    getBanner: (state) =>
      state.user && state.user.bannerUrl ? state.user.bannerUrl : null,
  },
};
