export default {
  methods: {
    setTheme() {
      const dark = localStorage.getItem("dark");
      if (!dark) {
        localStorage.setItem("dark", true);
      } else {
        const body = document.getElementById("body");
        if (dark === 'true') {
          body.classList.remove("light-theme");
        } else if (dark === 'false') {
          body.classList.add("light-theme");
        } else {
          localStorage.setItem("dark", true);
        }
      }
    },
    toggleTheme(val) {
      localStorage.setItem("dark", val);
      this.setTheme();
    }
  }
};
