<template>
  <div>
    <Preloader v-if="getLoading" :notice="getNotice" />
    <ModalWindow
      v-if="getSuccess"
      :can-close-modal="getCanCloseModal"
      type="success"
      :content="getSuccess"
    />
    <ModalWindow
      v-if="getError"
      :can-close-modal="getCanCloseModal"
      type="failure"
      :content="getError"
    />
    <ModalWindow
      v-if="getNeutral"
      :can-close-modal="getCanCloseModal"
      type="neutral"
      :content="getNeutral"
    />
    <router-view />
  </div>
</template>

<script>
import Preloader from "@/components/common/Preloader";
import getLoadingMixin from "@/mixins/getLoadingMixin";
import ModalWindow from "@/components/modals/ModalWindow";
import getAllApplicationStatesMixin from "@/mixins/getAllApplicationStatesMixin";
import getIsAuthorizedMixin from "@/mixins/getIsAuthorizedMixin";
import setLoadingMixin from "@/mixins/setLoadingMixin";
import { mapActions, mapGetters, mapMutations } from "vuex";
import connectWalletMixin from "@/mixins/connectWalletMixin";
import inputNumberMixin from "@/mixins/inputNumberMixin";
import setThemeMixin from "@/mixins/setThemeMixin";
import errors from "@/util/errors";

export default {
  name: "App",
  mixins: [
    getLoadingMixin,
    getAllApplicationStatesMixin,
    getIsAuthorizedMixin,
    setLoadingMixin,
    connectWalletMixin,
    inputNumberMixin,
    setThemeMixin,
  ],
  computed: {
    ...mapGetters({
      isWrongChainId: "wallet/isWrongChainId",
      getCanCloseModal: "general/getCanCloseModal",
    }),
  },
  watch: {
    getLoading() {
      this.inputNumber();
    },
    $route() {
      this.inputNumber();
    },
    isWrongChainId(val) {
      if (val) {
        this.setCanCloseModal(false);
        this.setError(errors.WRONG_NETWORK);
      } else {
        this.setError(null);
        this.setCanCloseModal(true);
      }
    },
    getIsAuthorized(val) {
      if (val) {
        this.init();
      }
    },
  },
  methods: {
    ...mapActions({
      getUserInfo: "user/getUserInfo",
      loadUsdPrice: "price/loadUsdPrice",
    }),
    ...mapMutations({
      setError: "general/setError",
      setLoadingCanBeInterrupted: "general/setLoadingCanBeInterrupted",
      setCanCloseModal: "general/setCanCloseModal",
    }),
    async init() {
      try {
        this.setTheme();
        this.setLoading(true);
        this.setLoadingCanBeInterrupted(false);
        await this.loadUsdPrice();
        if (this.getIsAuthorized) {
          await this.getUserInfo();
          await this.tryConnect();
        }
        this.setLoadingCanBeInterrupted(true);
        this.setLoading(false);
      } catch {
        this.setLoadingCanBeInterrupted(true);
        this.setLoading(false);
      }
    },
  },
  async mounted() {
    await this.init();
    this.inputNumber();
  },
  components: { ModalWindow, Preloader },
};
</script>

<style>
@import "assets/css/skin.css";
</style>
