export default {
  namespaced: true,
  state: {
    token: null,
    updatedTokens: [],
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setUpdatedToken(state, payload) {
      state.updatedTokens.push(payload);
    },
  },
  getters: {
    getSocketToken: (state) => state.token,
    getUpdatedTokensLength: (state) => state.updatedTokens.length,
    getUpdatedTokens: (state) => state.updatedTokens,
  },
};
