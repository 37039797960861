import languages from "@/util/languages";
import i18n from "@/i18n";

export default {
  namespaced: true,
  state: {
    language: null,
  },
  getters: {
    getLanguage: (state) => state.language,
  },
  mutations: {
    setLanguage(state, payload) {
      state.language = payload;
      localStorage.setItem("lang", payload);
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    reinstallI18n({ commit }, locale) {
      i18n.locale = locale || languages[0];
      i18n.fallbackLocale = locale || languages[0];
    },
  },
};
