import api from "@/api/api";
import routePaths from "@/util/routePaths";
import languages from "@/util/languages";
import networks from "@/util/networks";

const nftLink = (language, id, currency, contract = "") => {
  return `/${language ? `${language}/` : ""}${routePaths.NFT_PATH}/${currency}${
    contract ? `/${contract}` : ""
  }/${id}`;
};

const getContractAddressByCurrency = (currency) => {
  return (
    networks.find((i) => i.symbol === currency.toUpperCase())?.nftContract || ""
  );
};

const nftGuard = async (to, from, next) => {
  try {
    const language =
      to.params.lang || localStorage.getItem("lang") || languages[0];
    const response = await api.existsTokenByBchId({
      blockChainId: to.params.id,
      currency: to.params.tokenCurrency,
    });
    if (response) {
      if (to.params.contract) {
        next();
      } else {
        next(
          nftLink(
            language,
            to.params.id,
            to.params.tokenCurrency,
            getContractAddressByCurrency(to.params.tokenCurrency)
          )
        );
      }
    } else {
      if (to.params.contract) {
        nftLink(language, to.params.id, to.params.tokenCurrency);
      } else {
        next();
      }
    }
  } catch (e) {
    next("/404");
  }
};

export default nftGuard;
