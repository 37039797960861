export default {
  WRONG_NETWORK: "f-e-1",
  NEED_LOGIN: "f-e-2",
  TOKEN_DIFFERENT_NETWORK_FIRST: "f-e-3-first",
  TOKEN_DIFFERENT_NETWORK_SECOND: "f-e-3-second",
  TOKEN_DIFFERENT_NETWORK_THIRD: "f-e-3-third",
  AUCTION_HAS_ACTIVE_BIDS: "f-e-4",
  START_TIME_LESS_THAN_CURRENT: "f-e-5",
  END_TIME_LESS_THAN_CURRENT: "f-e-6",
  END_TIME_LESS_THAN_START: "f-e-7",
  NEGATIVE_PRICE: "f-e-8",
  REQUIRED_FIELD: "f-e-9",
  NEED_FILE_UPLOAD: "f-e-10",
  MAX_LENGTH_ERROR: "f-e-11",
  MIN_BID_REQUIRED: "f-e-12",
  GAS_PRICE_WARNING: "f-e-13",
  INVALID_EMAIL: "f-e-14",
  WRONG_URL: "f-e-15",
  WRONG_FILE_TYPE: "f-e-16",
  VALUE_RANGE_ERROR: "f-e-17",
  WRONG_CHAIN: "f-e-18",
  CANT_BURN_TOKEN: "f-e-19",
  CONNECTION_REQUEST_ALREADY_SENT: "-32002",
};
