import Web3 from "web3";
import contract from "@/api/contract";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Fortmatic from "fortmatic";
import networks from "@/util/networks";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    address: null,
    balance: null,
    chainId: null,
    provider: null,
    currentNetwork: null,
    isEthereumDetected: false,
    infuraId: "9e2b6db1a6f84a3fbfa4a4f7669eb568",
    availableNetworks: ["ethereum", "polygon"],
    network: localStorage.getItem("network") || "ethereum",
    currentConnectionMethod: localStorage.getItem("connectionMethod"),
    methodsInitialized: false,
  },
  mutations: {
    setMethodsInitialized(state, payload) {
      state.methodsInitialized = payload;
    },
    unsetWallet(state) {
      contract.initWeb3Methods(null);
      if (state.currentConnectionMethod !== "metamask") {
        state.provider.disconnect();
      }
      state.chainId = null;
      state.balance = null;
      state.address = null;
      state.currentConnectionMethod = null;
      localStorage.removeItem("connectionMethod");
    },
    setNetwork(state, payload) {
      state.network = payload;
      localStorage.setItem("network", payload);
    },
    setChainId(state, payload) {
      state.chainId = payload;
    },
    setBalance(state, payload) {
      state.balance = payload;
    },
    setAddress(state, payload) {
      state.address = payload;
    },
    setProvider(state, payload) {
      state.provider = payload;
    },
    setIsEthereumDetected(state, payload) {
      state.isEthereumDetected = payload;
    },
  },
  actions: {
    setDefaultProvider({ rootState }) {
      window.web3 = new Web3(
        new Web3.providers.HttpProvider(
          networks.find((i) => i.network === rootState.wallet.network).rpcUrl
        )
      );
    },
    async setChain({ rootState, state }, payload) {
      let network = null;
      if (payload.symbol) {
        network = networks.find((i) => i.symbol === payload.symbol);
      } else {
        network = networks.find((i) => i.network === rootState.wallet.network);
      }
      //if (getters.isWrongChainId || payload) {
      try {
        await state.provider.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: network.chainIdHex,
            },
          ],
        });
      } catch (e) {
        try {
          await state.provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: network.chainIdHex,
                rpcUrls: [network.rpcUrl],
                chainName: network.name,
                nativeCurrency: {
                  name: network.symbol,
                  symbol: network.symbol,
                  decimals: network.decimals,
                },
                blockExplorerUrls: [network.blockExplorerUrl],
              },
            ],
          });
          await state.provider.request({
            method: "wallet_switchEthereumChain",
            params: [
              {
                chainId: network.chainIdHex,
              },
            ],
          });
        } catch {
          return -1;
        }
      }

      //}
    },
    async connectWallet({ commit, rootState, dispatch }, connectionMethod) {
      if (
        connectionMethod === "metamask" &&
        !rootState.wallet.isEthereumDetected
      ) {
        dispatch("setDefaultProvider");
        throw new Error("ethereum_not_detected");
      }
      let provider = null;
      const network = networks.find(
        (i) => i.network === rootState.wallet.network
      );
      if (connectionMethod === "metamask") {
        provider = window.ethereum;
        window.web3 = new Web3(provider);
        const accounts = await window.web3.eth.getAccounts();
        if (!accounts.length && router.currentRoute.name !== "SignIn") {
          dispatch("setDefaultProvider");
          throw new Error("metamask_locked");
        }
        commit("setProvider", provider);
        await window.ethereum.request({ method: "eth_requestAccounts" });
        await dispatch("setChain", {});
      }
      if (connectionMethod === "fortmatic") {
        const fm = new Fortmatic("pk_test_F1FA8F780001E872", {
          rpcUrl: network.rpcUrl,
          chainId: network.chainIdDec,
        });
        provider = fm.getProvider();
        window.web3 = new Web3(provider);
      }
      if (connectionMethod === "walletconnect") {
        provider = new WalletConnectProvider({
          infuraId: rootState.wallet.infuraId,
          chainId: network.chainIdDec,
          rpc: {
            [`${network.chainIdDec}`]: network.rpcUrl,
          },
          qrcodeModalOptions: {
            mobileLinks: ["metamask"],
          },
        });
        await provider.enable();
        window.web3 = new Web3(provider);
      }
      if (!connectionMethod) {
        dispatch("setDefaultProvider");
        return;
      }
      commit("setProvider", provider);
      const accounts = await window.web3.eth.getAccounts();
      let balance = await window.web3.eth.getBalance(accounts[0]);
      let chainId = await window.web3.eth.getChainId();
      commit("setAddress", accounts[0]);
      commit("setBalance", balance);
      commit("setChainId", chainId);
      contract.initWeb3Methods(accounts[0]);
      contract.initWalletName(connectionMethod);
      localStorage.setItem("connectionMethod", connectionMethod);

      if (provider && connectionMethod !== "fortmatic") {
        provider.on("chainChanged", async (res) => {
          if (rootState.user.isAuthorized) {
            balance = await window.web3.eth.getBalance(accounts[0]);
            const network = networks.find(
              (i) => i.chainIdDec === res || i.chainIdHex === res
            );
            commit("setChainId", res);
            contract.initWeb3Methods(accounts[0]);
            commit("setBalance", balance);
            if (network) {
              commit("setNetwork", network.network);
            }
            if (rootState.wallet.currentConnectionMethod === "metamask") {
              await dispatch("setChain", {});
            }
          }
        });
        provider.on("accountsChanged", async () => {
          if (rootState.user.isAuthorized) {
            commit("unsetWallet");
            dispatch("user/logOut", {}, { root: true });
          }
        });
      }
      return { address: accounts[0] };
    },
  },
  getters: {
    getAvailableNetworks: (state) => state.availableNetworks,
    getMethodsInitialized: (state) => state.methodsInitialized,
    getNetwork: (state) => state.network,
    getMultiplier: (state) => {
      let network = networks.find(
        (i) => i.chainIdHex === state.chainId || i.chainIdDec === state.chainId
      );
      if (network) {
        return network.multiplier;
      } else return 0;
    },
    getChainIdDec: (state) => {
      let network = networks.find(
        (i) => i.chainIdHex === state.chainId || i.chainIdDec === state.chainId
      );
      if (network) {
        return network.chainIdDec;
      } else return 0;
    },
    getChainIdHex: (state) => {
      let network = networks.find(
        (i) => i.chainIdHex === state.chainId || i.chainIdDec === state.chainId
      );
      if (network) {
        return network.chainIdHex;
      } else return 0;
    },
    getSymbol: (state) => {
      let network = networks.find(
        (i) => i.chainIdHex === state.chainId || i.chainIdDec === state.chainId
      );
      if (network) {
        return network.symbol;
      } else return "";
    },
    getIsEthereumDetected: (state) => state.isEthereumDetected,
    getContract: (state) => {
      let network = networks.find(
        (i) => i.chainIdHex === state.chainId || i.chainIdDec === state.chainId
      );
      if (network) {
        return network.nftContract;
      } else return "";
    },
    getMarketContract: (state) => {
      let network = networks.find(
        (i) => i.chainIdHex === state.chainId || i.chainIdDec === state.chainId
      );
      if (network) {
        return network.marketContract;
      } else return "";
    },
    getForwarderContract: (state) => {
      let network = networks.find(
        (i) => i.chainIdHex === state.chainId || i.chainIdDec === state.chainId
      );
      if (network) {
        return network.forwarderContract;
      } else return "";
    },
    getAdminContract: (state) => {
      let network = networks.find(
        (i) => i.chainIdHex === state.chainId || i.chainIdDec === state.chainId
      );
      if (network) {
        return network.adminContract;
      } else return "";
    },
    walletConnected: (state) => !!state.address,
    getBalance: (state) => Number((state.balance / 1e18).toFixed(4)),
    getAddress: (state) => state.address,
    isWrongChainId: (state, getters, rootState) => {
      return (
        state.chainId &&
        rootState.user.isAuthorized &&
        !networks.find((i) => i.chainIdDec === state.chainId) &&
        !networks.find((i) => i.chainIdHex === state.chainId)
      );
    },
  },
};
