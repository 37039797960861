export default {
  namespaced: true,
  state: {
    error: null,
    success: null,
    neutral: null,
    loading: false,
    notice: null,
    needRefresh: null,
    loadingCanBeInterrupted: true,
    canCloseModal: true,
  },
  mutations: {
    setNotice(state, payload) {
      state.notice = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setLoadingCanBeInterrupted(state, payload) {
      state.loadingCanBeInterrupted = payload;
    },
    setLoading(state, payload) {
      if (state.loadingCanBeInterrupted) {
        state.loading = payload;
      }
    },
    setSuccess(state, payload) {
      state.success = payload;
    },
    setNeutral(state, payload) {
      state.neutral = payload;
    },
    setNeedRefresh(state, payload) {
      state.needRefresh = payload;
    },
    setCanCloseModal(state, payload) {
      state.canCloseModal = payload;
    },
  },
  getters: {
    getSuccess: (state) => state.success,
    getError: (state) => state.error,
    getLoading: (state) => state.loading,
    getNeutral: (state) => state.neutral,
    getNeedRefresh: (state) => state.needRefresh,
    getCanCloseModal: (state) => state.canCloseModal,
    getNotice: (state) => state.notice,
  },
};
