import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import beforeEachGuard from "@/router/guards/BeforeEachGuard";

Vue.use(VueRouter);

const lang = localStorage.getItem("lang");
if (!lang) {
  localStorage.setItem("lang", "en");
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(beforeEachGuard);

export default router;
