import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vueAwesomeCountdown from "vue-awesome-countdown";
import VueEllipseProgress from "vue-ellipse-progress";
import ScrollLoader from "vue-scroll-loader";
import VueLazyload from "vue-lazyload";
import VueSocialSharing from "vue-social-sharing";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import i18n from "./i18n";

Vue.component("datetime", Datetime);

Vue.config.productionTip = false;

Vue.use(vueAwesomeCountdown, "vac");
Vue.use(VueSocialSharing);
Vue.use(VueEllipseProgress, "VueEllipseProgress");
Vue.use(ScrollLoader);
Vue.use(Datetime);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: require("@/assets/img/preloader.svg"),
  attempt: 1,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
