export default {
  namespaced: true,
  state: {
    search: "",
  },
  getters: {
    getSearch: (state) => state.search,
  },
  mutations: {
    setSearch(state, payload) {
      state.search = payload;
    },
  },
};
