import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getLoading: "general/getLoading",
      getSuccess: "general/getSuccess",
      getError: "general/getError",
      getNeutral: "general/getNeutral",
      getNotice: "general/getNotice",
    }),
  },
};
