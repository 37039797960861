import http from "@/util/http-common";

const authController = {
  AUTH: "/auth",
  GET_SIGN_TOKEN: "/auth/sign-token",
};

const userController = {
  USER: "/user",
  UPLOAD_AVATAR: "/user/upload_avatar",
  UPLOAD_BANNER: "/user/upload_banner",
  DELETE_AVATAR: "/user/avatar",
  DELETE_BANNER: "/user/banner",
  BY_ID: "/user/by_id",
};

const nftMintingController = {
  NFT_MINTING: "/nft_minting",
  DELETE_PREVIEW: "/nft_minting/preview_file",
  DELETE_TOKEN: "/nft_minting/main_file",
  TAG: "/nft_minting/tag",
  NFT_MINTING_META_TX: "/nft_minting/meta-transaction/signature",
  NFT_MINTING_META_TX_COMPLETE: "/nft_minting/complete/meta-transaction",
  COLLECTION: "/nft_minting/collection",
};

const displayNftController = {
  NFT: "/nft",
  CREATED: "/nft/created",
  OWN: "/nft/own",
  OWN_SEARCH: "/nft/own/search",
  BLOCK_CHAIN_ID: "/nft/block_chain_id",
  INNER_ID: "/nft/inner_id",
  FILTER: "/nft/filter",
  BY_COLLECTION_ID: "/nft/by_collection_id",
  BY_COLLECTION_ID_EXCLUDING_TOKEN_ID:
    "/nft/by_collection_id_excluding_token_id",
  SEARCH: "/nft/search",
  BIDS: "/nft/bids",
  HISTORY: "/nft/history",
  LIVE_AUCTION: "/nft/live_auction",
  BY_TAG: "/nft/by_tag",
};

const creatorsController = {
  CREATORS: "/creators",
  SEARCH: "/creators/search",
};

const subscribesController = {
  SUBSCRIBES: "/subscribes",
};

const displayCollectionController = {
  NFT_COLLECTION: "/nft_collection",
  FOR_CREATING_NFT: "/nft_collection/for_creating_nft",
  BY_ID: "/nft_collection/by_id",
  SEARCH: "/nft_collection/search",
  CHANGE_TOKEN_COLLECTION: "/nft_collection/change_token_collection",
  OWN_SEARCH: "/nft_collection/own/search",
  ADD_TOKEN_TO_COLLECTION: "/nft_collection/add_token_to_collection",
  REMOVE_TOKEN_FROM_COLLECTION: "/nft_collection/remove_token_from_collection",
};

const mainPageSettingsController = {
  GET_ALL: "/settings/main_page/main_content",
  GET_CREATORS: "/settings/main_page/get_creators",
  GET_LIVE_AUCTIONS: "/settings/main_page/get_live_auctions",
  GET_NFT_COLLECTIONS: "/settings/main_page/get_nft_collections",
  GET_NFT_TOKENS: "/settings/main_page/get_nft_tokens",
};

const utilPriceController = {
  PRICES: "/prices",
};

const reportController = {
  REPORT: "/reports",
};

const userActivityController = {
  GET_ACTIVITY: "/activity",
  GET_ACTIVITY_BY_TYPE: "/activity/type",
};

const nftTokenController = {
  NFT_TOKEN: "/nft_token",
  TAG: "/nft_token/tag",
  META_TRANSACTION: "/nft_token/meta-transaction",
  META_TOKEN: "/nft_token/meta-token",
  EXISTS_TOKEN: "/nft_token/exists-token",
};

export default {
  async updateTokenData(payload) {
    const response = await http.put(
      nftTokenController.NFT_TOKEN,
      payload.body,
      {
        params: {
          ...payload.query,
          isPublic: false,
        },
      }
    );
    return response.data;
  },

  async getMetaTransactionInfo(payload) {
    const response = await http.get(nftTokenController.META_TRANSACTION, {
      params: {
        ...payload,
        isPublic: false,
      },
    });
    return response.data;
  },

  async deleteMetaToken(payload) {
    const response = await http.delete(nftTokenController.META_TOKEN, {
      params: {
        ...payload,
        isPublic: false,
      },
    });
    return response.data;
  },

  async existsTokenByBchId(payload) {
    const response = await http.get(nftTokenController.EXISTS_TOKEN, {
      params: {
        ...payload,
        isPublic: false,
      },
    });
    return response.data;
  },

  async getActivity(payload) {
    const response = await http.get(userActivityController.GET_ACTIVITY, {
      params: {
        ...payload,
        isPublic: false,
      },
    });
    return response.data;
  },

  async getActivityByType(payload) {
    const response = await http.get(
      userActivityController.GET_ACTIVITY_BY_TYPE,
      {
        params: {
          ...payload,
          isPublic: false,
        },
      }
    );
    return response.data;
  },

  async sendReport(payload) {
    await http.post(reportController.REPORT, payload, {
      params: {
        isPublic: false,
      },
    });
  },

  async getUsdPrice(payload) {
    const response = await http.get(utilPriceController.PRICES, {
      params: {
        ...payload,
        isPublic: true,
      },
    });
    return response.data;
  },

  async getAllAdminObjects() {
    const response = await http.get(mainPageSettingsController.GET_ALL, {
      params: {
        isPublic: true,
      },
    });
    return response.data;
  },

  async getMainPageCreators(payload) {
    const response = await http.get(mainPageSettingsController.GET_CREATORS, {
      params: {
        ...payload,
        isPublic: true,
      },
    });
    return response.data;
  },

  async getMainPageLiveAuctionsTokens(payload) {
    const response = await http.get(
      mainPageSettingsController.GET_LIVE_AUCTIONS,
      {
        params: {
          ...payload,
          isPublic: true,
        },
      }
    );
    return response.data;
  },

  async getMainPageNftCollections(payload) {
    const response = await http.get(
      mainPageSettingsController.GET_NFT_COLLECTIONS,
      {
        params: {
          ...payload,
          isPublic: true,
        },
      }
    );
    return response.data;
  },

  async getMainPageNftTokens(payload) {
    const response = await http.get(mainPageSettingsController.GET_NFT_TOKENS, {
      params: {
        isPublic: true,
        ...payload,
      },
    });
    return response.data;
  },

  async getNftCollectionById(payload) {
    const response = await http.get(displayCollectionController.BY_ID, {
      params: {
        ...payload,
        isPublic: true,
      },
    });
    return response.data;
  },

  async searchCollections(payload) {
    const response = await http.get(displayCollectionController.SEARCH, {
      params: {
        ...payload,
        isPublic: true,
      },
    });
    return response.data;
  },

  async changeTokenCollection(payload) {
    const response = await http.post(
      displayCollectionController.CHANGE_TOKEN_COLLECTION,
      null,
      {
        params: {
          ...payload,
          isPublic: false,
        },
      }
    );
    return response.data;
  },

  async getUserNftCollections(payload) {
    const response = await http.get(
      displayCollectionController.FOR_CREATING_NFT,
      {
        params: {
          ...payload,
          isPublic: false,
        },
      }
    );
    return response.data;
  },

  async getNftCollections(payload) {
    const response = await http.get(
      displayCollectionController.NFT_COLLECTION,
      {
        params: {
          ...payload,
          isPublic: true,
        },
      }
    );
    return response.data;
  },

  async createNftCollection(payload) {
    const response = await http.post(
      displayCollectionController.NFT_COLLECTION,
      payload,
      {
        params: {
          isPublic: false,
        },
      }
    );
    return response.data;
  },

  async updateNfTCollection(payload) {
    const response = await http.put(
      displayCollectionController.NFT_COLLECTION,
      payload.body,
      {
        params: {
          ...payload.query,
          isPublic: false,
        },
      }
    );
    return response.data;
  },

  async deleteNftCollection(payload) {
    const response = await http.delete(
      displayCollectionController.NFT_COLLECTION,
      {
        params: {
          ...payload,
          isPublic: false,
        },
      }
    );
    return response.data;
  },

  async subscribe(payload) {
    const response = await http.post(subscribesController.SUBSCRIBES, null, {
      params: {
        ...payload,
        isPublic: false,
      },
    });
    return response.data;
  },

  async unsubscribe(payload) {
    const response = await http.delete(subscribesController.SUBSCRIBES, {
      params: {
        ...payload,
        isPublic: false,
      },
    });
    return response.data;
  },

  async getSubscribesInfo(payload) {
    const response = await http.get(subscribesController.SUBSCRIBES, {
      params: {
        ...payload,
        isPublic: false,
      },
    });
    return response.data;
  },

  async getCreators(payload) {
    const response = await http.post(
      creatorsController.CREATORS,
      payload.body,
      {
        params: {
          ...payload.query,
          isPublic: true,
        },
      }
    );
    return response.data;
  },

  async searchCreators(payload) {
    const response = await http.get(creatorsController.SEARCH, {
      params: {
        ...payload,
        isPublic: true,
      },
    });
    return response.data;
  },

  async getUserInfo() {
    const response = await http.get(userController.USER, {
      params: {
        isPublic: false,
      },
    });
    return response.data;
  },

  async getUserById(payload) {
    const response = await http.get(userController.BY_ID, {
      params: {
        isPublic: true,
        ...payload,
      },
    });
    return response.data;
  },

  async updateUserInfo(payload) {
    return await http.post(userController.USER, payload, {
      params: { isPublic: false },
    });
  },

  async deleteAvatar() {
    return await http.delete(userController.DELETE_AVATAR, {
      params: { isPublic: false },
    });
  },

  async deleteBanner() {
    return await http.delete(userController.DELETE_BANNER, {
      params: { isPublic: false },
    });
  },

  async uploadAvatar(payload) {
    return await http.post(userController.UPLOAD_AVATAR, payload, {
      params: { isPublic: false },
    });
  },

  async uploadBanner(payload) {
    return await http.post(userController.UPLOAD_BANNER, payload, {
      params: { isPublic: false },
    });
  },

  async getPublicNftTokens(payload) {
    const response = await http.get(displayNftController.NFT, {
      params: {
        ...payload,
        isPublic: true,
      },
    });
    return response.data;
  },

  async getPublicNftTokensByFilter(payload) {
    const response = await http.post(
      displayNftController.FILTER,
      payload.body,
      {
        params: {
          ...payload.query,
          isPublic: true,
        },
      }
    );
    return response.data;
  },

  async getNftTokenByCollectionId(payload) {
    const response = await http.get(displayNftController.BY_COLLECTION_ID, {
      params: {
        ...payload,
        isPublic: true,
      },
    });
    return response.data;
  },

  async getPublicNftTokensWithLiveAuction(payload) {
    const response = await http.get(displayNftController.LIVE_AUCTION, {
      params: {
        ...payload,
        isPublic: true,
      },
    });
    return response.data;
  },

  async getNFTTokenByTag(payload) {
    const response = await http.get(displayNftController.BY_TAG, {
      params: {
        ...payload,
        isPublic: true,
      },
    });
    return response.data;
  },

  async getNftTokenByCollectionIdExcludingToken(payload) {
    const response = await http.get(
      displayNftController.BY_COLLECTION_ID_EXCLUDING_TOKEN_ID,
      {
        params: {
          ...payload,
          isPublic: true,
        },
      }
    );
    return response.data;
  },

  async searchNfts(payload) {
    const response = await http.get(displayNftController.SEARCH, {
      params: {
        ...payload,
        isPublic: true,
      },
    });
    return response.data;
  },

  async getNftTokenBids(payload) {
    const response = await http.get(displayNftController.BIDS, {
      params: {
        ...payload,
        isPublic: true,
      },
    });
    return response.data;
  },

  async getNftTokenHistory(payload) {
    const response = await http.get(displayNftController.HISTORY, {
      params: {
        ...payload,
        isPublic: true,
      },
    });
    return response.data;
  },

  async getNftTokenByBlockChainId(payload) {
    const response = await http.get(displayNftController.BLOCK_CHAIN_ID, {
      params: {
        ...payload,
        isPublic: true,
      },
    });
    return response.data;
  },

  async getNftTokenByInnerId(payload) {
    const response = await http.get(displayNftController.INNER_ID, {
      params: {
        ...payload,
        isPublic: true,
      },
    });
    return response.data;
  },

  async getCreatedNft(payload) {
    const response = await http.get(displayNftController.CREATED, {
      params: {
        ...payload,
        isPublic: true,
      },
    });
    return response.data;
  },

  async getOwnNft(payload) {
    const response = await http.get(displayNftController.OWN, {
      params: {
        ...payload,
        isPublic: true,
      },
    });
    return response.data;
  },

  async ownNftSearch(payload) {
    const response = await http.get(displayNftController.OWN_SEARCH, {
      params: {
        ...payload,
        isPublic: false,
      },
    });
    return response.data;
  },

  async ownCollectionSearch(payload) {
    const response = await http.get(displayCollectionController.OWN_SEARCH, {
      params: {
        ...payload,
        isPublic: false,
      },
    });
    return response.data;
  },

  async addNFTTokenToCollection(payload) {
    const response = await http.post(
      displayCollectionController.ADD_TOKEN_TO_COLLECTION,
      null,
      {
        params: {
          ...payload,
          isPublic: false,
        },
      }
    );
    return response.data;
  },

  async removeTokenFromCollection(payload) {
    const response = await http.post(
      displayCollectionController.REMOVE_TOKEN_FROM_COLLECTION,
      null,
      {
        params: {
          ...payload,
          isPublic: false,
        },
      }
    );
    return response.data;
  },

  async nftMinting(payload) {
    const response = await http.post(
      nftMintingController.NFT_MINTING,
      payload,
      {
        params: { isPublic: false },
      }
    );
    return response.data;
  },

  async completeNftMinting(payload) {
    const response = await http.put(nftMintingController.NFT_MINTING, payload, {
      params: { isPublic: false },
    });
    return response.data;
  },

  async addCollectionToTokenImage(payload) {
    const response = await http.post(nftMintingController.COLLECTION, null, {
      params: { isPublic: false, ...payload },
    });
    return response.data;
  },

  async deleteCollectionFromTokenImage(payload) {
    const response = await http.delete(nftMintingController.COLLECTION, {
      params: { isPublic: false, ...payload },
    });
    return response.data;
  },

  async completeMetaTransactionNftMinting(payload) {
    const response = await http.put(
      nftMintingController.NFT_MINTING_META_TX,
      payload.body,
      {
        params: { isPublic: false, innerId: payload.query.innerId },
      }
    );
    return response.data;
  },

  async completeMetaTransaction(payload) {
    const response = await http.put(
      nftMintingController.NFT_MINTING_META_TX_COMPLETE,
      null,
      {
        params: { isPublic: false, ...payload },
      }
    );
    return response.data;
  },

  async getUploadedData() {
    const response = await http.get(nftMintingController.NFT_MINTING, {
      params: { isPublic: false },
    });
    return response.data;
  },

  async deleteTokenFile() {
    const response = await http.delete(nftMintingController.DELETE_TOKEN, {
      params: { isPublic: false },
    });
    return response.data;
  },

  async addTagToToken(payload) {
    return await http.post(nftTokenController.TAG, null, {
      params: { isPublic: false, ...payload },
    });
  },

  async deleteTagFromToken(payload) {
    return await http.delete(nftTokenController.TAG, {
      params: { isPublic: false, ...payload },
    });
  },

  async addTokenTag(payload) {
    return await http.post(nftMintingController.TAG, null, {
      params: { isPublic: false, ...payload },
    });
  },

  async deleteTokenTag(payload) {
    return await http.delete(nftMintingController.TAG, {
      params: { isPublic: false, ...payload },
    });
  },

  async deletePreviewTokenFile() {
    const response = await http.delete(nftMintingController.DELETE_PREVIEW, {
      params: { isPublic: false },
    });
    return response.data;
  },

  async auth(payload) {
    const response = await http.post(authController.AUTH, payload, {
      params: { isPublic: true },
    });
    return response.data;
  },

  async getSignToken(payload) {
    const response = await http.post(authController.GET_SIGN_TOKEN, null, {
      params: { isPublic: true, ...payload },
    });
    return response.data;
  },
};
