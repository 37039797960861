<template>
  <span class="preloader">
    <span class="image"><img src="@/assets/img/preloader.svg" /></span>
    <span class="title">{{ $t(notice) || $t("f-n-5") }}</span>
  </span>
</template>

<script>
export default {
  name: "Preloader",
  props: {
    notice: {
      required: true,
    },
  },
};
</script>
