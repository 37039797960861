export default {
  namespaced: true,
  state: {
    bid: null,
    history: null,
  },
  mutations: {
    setBid(state, payload) {
      state.bid = payload;
    },
    setHistory(state, payload) {
      state.history = payload;
    },
  },
  getters: {
    getBid: (state) => state.bid,
    getHistory: (state) => state.history,
  },
};
