<template>
  <div class="popup std">
    <div class="popup-overlay" v-if="canCloseModal" @click="close"></div>
    <div class="popup-overlay inactive" v-else></div>

    <div class="popup-container" :class="type">
      <div class="svg close" v-if="canCloseModal" @click="close">
        <svg><use xlink:href="#svg-close" /></svg>
      </div>

      <div class="popup-icon" v-if="type !== 'neutral'"></div>

      <div class="popup-content" :class="{ center: type !== 'neutral' }">
        <h3 v-if="type === 'success'">Success</h3>
        <h3 v-if="type === 'failure'">Error</h3>
        <h3 v-if="type === 'neutral'">{{ title }}</h3>
        <div>{{ $t(content) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "ModalWindow",
  props: {
    type: {
      // neutral, success, failure
      type: String,
      required: false,
      default: () => "neutral",
    },
    title: {
      type: String,
      required: false,
      default: () => "Info",
    },
    content: {
      type: String,
      required: true,
    },
    canCloseModal: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  methods: {
    ...mapMutations({
      setSuccess: "general/setSuccess",
      setError: "general/setError",
      setNeutral: "general/setNeutral",
    }),
    close() {
      switch (this.type) {
        case "neutral":
          this.setNeutral(null);
          break;
        case "success":
          this.setSuccess(null);
          break;
        case "failure":
          this.setError(null);
          break;
      }
    },
  },
};
</script>
