export default {
  methods: {
    inputNumber() {
      setTimeout(() => {
        const inputBox = document.querySelectorAll("input[type=number]");
        const invalidChars = ["-", "+", "e"];

        for (const item of inputBox) {
          item.setAttribute("pattern", "^\\d+(\\.\\d+)*$");
          item.addEventListener("wheel", (e) => {
            e.preventDefault();
          });
          item.addEventListener("keydown", (e) => {
            if (e.target.value.includes(".")) {
              if (
                e.target.value.split(".")[1].length === 8 &&
                e.key !== "Backspace"
              ) {
                e.preventDefault();
              }
            }
            if (e.target.value.includes(",")) {
              if (
                e.target.value.split(",")[1].length === 8 &&
                e.key !== "Backspace"
              ) {
                e.preventDefault();
              }
            }
            if (invalidChars.includes(e.key)) {
              e.preventDefault();
            }
            if (e.key === "ArrowUp" || e.key === "ArrowDown") {
              e.preventDefault();
            }
          });
        }
      }, 1000);
    },
  },
};
