import AuthGuard from "@/router/guards/AuthGuard";
import routePaths from "@/util/routePaths";
import routeNames from "@/util/routeNames";
import nftGuard from "@/router/guards/NftGuard";
import nftEditGuard from "@/router/guards/NftEditGuard";

const language = localStorage.getItem("lang");

export default [
  {
    path: routePaths.APP_ROOT_PATH,
    redirect: `/${language ? `${language}/` : ""}${routePaths.HOME_PATH}`,
  },
  {
    path: routePaths.LANGUAGE_STACK_PATH,
    component: () =>
      import(
        /*webpackChunkName: "language-stack"*/ "@/views/LanguageStack.vue"
      ),
    children: [
      {
        name: routeNames.HEADER_STACK,
        path: routePaths.APP_EMPTY_PATH,
        component: () =>
          import(
            /*webpackChunkName: "header-stack"*/ "@/views/HeaderStack.vue"
          ),
        children: [
          {
            name: routeNames.HOME,
            path: routePaths.HOME_PATH,
            component: () =>
              import(/*webpackChunkName: "home-view"*/ "@/views/Home.vue"),
          },
          {
            name: routeNames.COLLECTIONS,
            path: routePaths.COLLECTIONS_PATH,
            component: () => import("@/views/Collections.vue"),
          },
          {
            name: routeNames.ARTWORKS,
            path: routePaths.ARTWORKS_PATH,
            component: () => import("@/views/Artworks.vue"),
          },
          {
            name: routeNames.CREATORS,
            path: routePaths.CREATORS_PATH,
            component: () => import("@/views/Creators.vue"),
          },
          {
            name: routeNames.ACTIVITY,
            path: `${routePaths.ACTIVITY_PATH}${routePaths.ACTIVITY_PATH_PARAMS}`,
            props: true,
            meta: {
              requiresAuth: true,
            },
            component: () => import("@/views/Activity.vue"),
          },
          {
            name: routeNames.SETTINGS,
            path: routePaths.SETTINGS_PATH,
            component: () => import("@/views/Settings.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            name: routeNames.CREATE,
            path: routePaths.CREATE_PATH,
            meta: {
              requiresAuth: true,
            },
            component: () => import("@/views/TokenCreate.vue"),
          },
          {
            name: routeNames.COLLECTION,
            path: `${routePaths.COLLECTIONS_PATH}${routePaths.COLLECTION_PATH_PARAMS}`,
            props: true,
            component: () => import("@/views/CollectionPage.vue"),
          },
          {
            name: routeNames.EDIT_COLLECTION,
            path: `${routePaths.EDIT_COLLECTION_PATH}${routePaths.COLLECTION_PATH_PARAMS}`,
            meta: {
              requiresAuth: true,
            },
            props: true,
            component: () => import("@/views/EditCollection.vue"),
          },
          {
            name: routeNames.NFT,
            path: `${routePaths.NFT_PATH}${routePaths.NFT_PATH_PARAMS}`,
            props: true,
            beforeRouteEnter: nftGuard,
            component: () => import("@/views/ArtworkPage.vue"),
          },
          {
            name: routeNames.NFT_EDIT,
            path: `${routePaths.NFT_EDIT_PATH}${routePaths.NFT_PATH_PARAMS}`,
            meta: {
              requiresAuth: true,
            },
            props: true,
            beforeRouteEnter: nftEditGuard,
            component: () => import("@/views/TokenEdit.vue"),
          },
          {
            name: routeNames.PROFILE,
            path: `${routePaths.PROFILE_PATH}${routePaths.PROFILE_PATH_PARAMS}`,
            meta: {
              requiresAuth: false,
            },
            props: true,
            component: () => import("@/views/Profile.vue"),
          },
          {
            name: routeNames.SIGN_IN,
            path: routePaths.SIGN_IN_PATH,
            component: () => import("@/views/SignIn"),
            beforeEnter: AuthGuard,
          },
          {
            name: routeNames.PRIVACY_POLICY,
            path: routePaths.PRIVACY_POLICY_PATH,
            component: () => import("@/views/PrivacyPolicy.vue"),
          },
          {
            name: routeNames.TERMS_OF_SERVICE,
            path: routePaths.TERMS_OF_SERVICE_PATH,
            component: () => import("@/views/TermsOfService.vue"),
          },
        ],
      },
      {
        path: "404",
        name: "404",
        component: () => import("../views/404"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/404",
  },
];
