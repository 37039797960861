import Store from "@/store";

const AuthGuard = async (to, from, next) => {
  if (Store.getters["user/getIsAuthorized"]) {
    next({
      path: "/",
    });
  } else {
    next();
  }
};

export default AuthGuard;
