export default {
  APP_ROOT_PATH: "/",
  APP_EMPTY_PATH: "",
  HOME_PATH: "home",
  COLLECTIONS_PATH: "collections",
  ARTWORKS_PATH: "artworks",
  CREATORS_PATH: "creators",
  LANGUAGE_STACK_PATH: "/:lang?",
  ACTIVITY_PATH: "activity",
  ACTIVITY_PATH_PARAMS: "/:tab",
  SETTINGS_PATH: "settings",
  CREATE_PATH: "create",
  COLLECTION_PATH: "collection",
  EDIT_COLLECTION_PATH: "collection/edit",
  COLLECTION_PATH_PARAMS: "/:id",
  NFT_PATH: "assets",
  NFT_EDIT_PATH: "nft/edit",
  NFT_PATH_PARAMS: "/:tokenCurrency/:contract?/:id",
  PROFILE_PATH: "profile",
  PROFILE_PATH_PARAMS: "/:id/:currentTab",
  SIGN_IN_PATH: "sign-in",
  PRIVACY_POLICY_PATH: "privacy-policy",
  TERMS_OF_SERVICE_PATH: "terms-of-service",
};
