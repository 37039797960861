export default {
  LANGUAGE_STACK: "LanguageStack",
  HEADER_STACK: "HeaderStack",
  HOME: "Home",
  COLLECTIONS: "Collections",
  ARTWORKS: "Artworks",
  CREATORS: "Creators",
  ACTIVITY: "Activity",
  SETTINGS: "Settings",
  CREATE: "Create",
  COLLECTION: "Collection",
  EDIT_COLLECTION: "EditCollection",
  NFT: "Nft",
  NFT_EDIT: "NftEdit",
  PROFILE: "Profile",
  SIGN_IN: "SignIn",
  PRIVACY_POLICY: "PrivacyPolicy",
  TERMS_OF_SERVICE: "TermsOfService",
};
