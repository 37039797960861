import marketAbi from "@/util/marketAbi";
import nftAbi from "@/util/nftAbi";
import royaltyAbi from "@/util/royaltyAbi";
import forwarderAbi from "@/util/forwarderAbi";
import store from "@/store";
import { ethers } from "ethers";

let marketMethods = null;
let nftMethods = null;
let adminMethods = null;
let forwarderMethods = null;
let account = null;
let walletName = null;

const initWeb3Methods = (payload) => {
  marketMethods = new window.web3.eth.Contract(
    marketAbi,
    store.getters["wallet/getMarketContract"]
  ).methods;
  nftMethods = new window.web3.eth.Contract(
    nftAbi,
    store.getters["wallet/getContract"]
  ).methods;
  adminMethods = new window.web3.eth.Contract(
    royaltyAbi,
    store.getters["wallet/getAdminContract"]
  ).methods;
  forwarderMethods = new window.web3.eth.Contract(
    forwarderAbi,
    store.getters["wallet/getForwarderContract"]
  ).methods;
  account = payload;
  store.commit("wallet/setMethodsInitialized", true);
};

const initWalletName = (payload) => {
  walletName = payload;
};

const checkIsWalletConnect = () => {
  return walletName === "walletConnect";
};

const formatPrice = (price, multiplier) => {
  let coefficient = !multiplier
    ? store.getters["wallet/getMultiplier"]
    : multiplier;
  return Math.round(price * coefficient).toLocaleString("fullwide", {
    useGrouping: false,
  });
};

//WRITE CONTRACT

const executeForMarket = (
  metaTxReq1,
  sellerSig,
  metaTxReq2,
  buyerSignature,
  price
) => {
  return new Promise((resolve, reject) => {
    forwarderMethods
      .executeForMarket(metaTxReq1, sellerSig, metaTxReq2, buyerSignature)
      .send(
        {
          value: formatPrice(price),
          from: account,
        },
        (err) => {
          if (err) {
            reject(err);
          }
          if (checkIsWalletConnect()) {
            resolve({
              isWalletConnect: true,
            });
          }
        }
      )
      .once("confirmation", (confirmationNumber, receipt) => {
        if (receipt.status === true) {
          resolve();
        } else {
          reject({
            message: "Transaction was not minted",
          });
        }
      });
  });
};

const changeCostSimpleAuction = (auctionId, newCost) => {
  return new Promise((resolve, reject) => {
    marketMethods
      .changeCostSimpleAuction(auctionId, formatPrice(newCost))
      .send(
        {
          from: account,
        },
        (err) => {
          if (err) {
            reject(err);
          }
          if (checkIsWalletConnect()) {
            resolve({
              isWalletConnect: true,
            });
          }
        }
      )
      .once("confirmation", (confirmationNumber, receipt) => {
        if (receipt.status === true) {
          resolve();
        } else {
          reject({
            message: "Transaction was not minted",
          });
        }
      });
  });
};

const checkPendingAuction = (auctionId) => {
  return new Promise((resolve, reject) => {
    marketMethods
      .checkPendingAuction(auctionId)
      .send(
        {
          from: account,
        },
        (err) => {
          if (err) {
            reject(err);
          }
          if (checkIsWalletConnect()) {
            resolve({
              isWalletConnect: true,
            });
          }
        }
      )
      .once("confirmation", (confirmationNumber, receipt) => {
        if (receipt.status === true) {
          resolve();
        } else {
          reject({
            message: "Transaction was not minted",
          });
        }
      });
  });
};

const closeAuctionByUser = (auctionId) => {
  return new Promise((resolve, reject) => {
    marketMethods
      .closeAuctionByUser(auctionId)
      .send(
        {
          from: account,
        },
        (err) => {
          if (err) {
            reject(err);
          }
          if (checkIsWalletConnect()) {
            resolve({
              isWalletConnect: true,
            });
          }
        }
      )
      .once("confirmation", (confirmationNumber, receipt) => {
        if (receipt.status === true) {
          resolve();
        } else {
          reject({
            message: "Transaction was not minted",
          });
        }
      });
  });
};

const createRateByTimeAuction = (payableAmount, auctionId) => {
  return new Promise((resolve, reject) => {
    marketMethods
      .createRateByTimeAuction(+auctionId)
      .send(
        {
          from: account,
          value: formatPrice(payableAmount),
        },
        (err) => {
          if (err) {
            reject(err);
          }
          if (checkIsWalletConnect()) {
            resolve({
              isWalletConnect: true,
            });
          }
        }
      )
      .once("confirmation", (confirmationNumber, receipt) => {
        if (receipt.status === true) {
          resolve();
        } else {
          reject({
            message: "Transaction was not minted",
          });
        }
      });
  });
};

const buyTokenBySimpleAuction = (auctionId, payableAmount) => {
  return new Promise((resolve, reject) => {
    marketMethods
      .buyTokenBySimpleAuction(+auctionId)
      .send(
        {
          from: account,
          value: formatPrice(payableAmount),
        },
        (err) => {
          if (err) {
            reject(err);
          }
          if (checkIsWalletConnect()) {
            resolve({
              isWalletConnect: true,
            });
          }
        }
      )
      .once("confirmation", (confirmationNumber, receipt) => {
        if (receipt.status === true) {
          resolve();
        } else {
          reject({
            message: "Transaction was not minted",
          });
        }
      });
  });
};

const createSimpleAuction = (tokenId, minBidPrice) => {
  return new Promise((resolve, reject) => {
    marketMethods
      .createSimpleAuction(tokenId, formatPrice(minBidPrice))
      .send(
        {
          from: account,
        },
        (err) => {
          if (err) {
            reject(err);
          }
          if (checkIsWalletConnect()) {
            resolve({
              isWalletConnect: true,
            });
          }
        }
      )
      .once("confirmation", (confirmationNumber, receipt) => {
        if (receipt.status === true) {
          resolve();
        } else {
          reject({
            message: "Transaction was not minted",
          });
        }
      });
  });
};

const createTimeAuction = (tokenId, startTime, endTime, minBidPrice) => {
  return new Promise((resolve, reject) => {
    marketMethods
      .createTimeAuction(tokenId, startTime, endTime, formatPrice(minBidPrice))
      .send(
        {
          from: account,
        },
        (err) => {
          if (err) {
            reject(err);
          }
          if (checkIsWalletConnect()) {
            resolve({
              isWalletConnect: true,
            });
          }
        }
      )
      .once("confirmation", (confirmationNumber, receipt) => {
        if (receipt.status === true) {
          resolve();
        } else {
          reject({
            message: "Transaction was not minted",
          });
        }
      });
  });
};

const createToken = (creatorPercent) => {
  return new Promise((resolve, reject) => {
    marketMethods
      .createToken(formatPrice(creatorPercent, 100000))
      .send(
        {
          from: account,
        },
        (err) => {
          if (err) {
            reject(err);
          }
          if (checkIsWalletConnect()) {
            resolve({
              isWalletConnect: true,
            });
          }
        }
      )
      .once("confirmation", (confirmationNumber, receipt) => {
        if (receipt.status === true) {
          resolve();
        } else {
          reject({
            message: "Transaction was not minted",
          });
        }
      });
  });
};

const createTokenAndSimpleAuction = (minBidPrice, creatorPercent) => {
  return new Promise((resolve, reject) => {
    marketMethods
      .createTokenAndSimpleAuction(
        formatPrice(creatorPercent, 100000),
        formatPrice(minBidPrice)
      )
      .send(
        {
          from: account,
        },
        (err) => {
          if (err) {
            reject(err);
          }
          if (checkIsWalletConnect()) {
            resolve({
              isWalletConnect: true,
            });
          }
        }
      )
      .once("confirmation", (confirmationNumber, receipt) => {
        if (receipt.status === true) {
          resolve();
        } else {
          reject({
            message: "Transaction was not minted",
          });
        }
      });
  });
};

const createTokenAndTimeAuction = (
  startTime,
  endTime,
  minBidPrice,
  creatorPercent
) => {
  return new Promise((resolve, reject) => {
    marketMethods
      .createTokenAndTimeAuction(
        formatPrice(creatorPercent, 100000),
        startTime,
        endTime,
        formatPrice(minBidPrice)
      )
      .send(
        {
          from: account,
        },
        (err) => {
          if (err) {
            reject(err);
          }
          if (checkIsWalletConnect()) {
            resolve({
              isWalletConnect: true,
            });
          }
        }
      )
      .once("confirmation", (confirmationNumber, receipt) => {
        if (receipt.status === true) {
          resolve();
        } else {
          reject({
            message: "Transaction was not minted",
          });
        }
      });
  });
};

const changeTimeAuction = (auctionId, newStartTime, newEndTime) => {
  return new Promise((resolve, reject) => {
    marketMethods
      .changeTimeAuction(auctionId, newStartTime, newEndTime)
      .send(
        {
          from: account,
        },
        (err) => {
          if (err) {
            reject(err);
          }
          if (checkIsWalletConnect()) {
            resolve({
              isWalletConnect: true,
            });
          }
        }
      )
      .once("confirmation", (confirmationNumber, receipt) => {
        if (receipt.status === true) {
          resolve();
        } else {
          reject({
            message: "Transaction was not minted",
          });
        }
      });
  });
};

const transferFrom = (to, tokenId) => {
  return new Promise((resolve, reject) => {
    nftMethods
      .transferFrom(account, to, tokenId)
      .send(
        {
          from: account,
        },
        (err) => {
          if (err) {
            reject(err);
          }
          if (checkIsWalletConnect()) {
            resolve({
              isWalletConnect: true,
            });
          }
        }
      )
      .once("confirmation", (confirmationNumber, receipt) => {
        if (receipt.status === true) {
          resolve();
        } else {
          reject({
            message: "Transaction was not minted",
          });
        }
      });
  });
};

const burn = (tokenId) => {
  return new Promise((resolve, reject) => {
    nftMethods
      .burn(tokenId)
      .send(
        {
          from: account,
        },
        (err) => {
          if (err) {
            reject(err);
          }
          if (checkIsWalletConnect()) {
            resolve({
              isWalletConnect: true,
            });
          }
        }
      )
      .once("confirmation", (confirmationNumber, receipt) => {
        if (receipt.status === true) {
          resolve();
        } else {
          reject({
            message: "Transaction was not minted",
          });
        }
      });
  });
};

//READ CONTRACT

const _royaltyPercent = () => {
  return new Promise((resolve, reject) => {
    adminMethods.maxRoyaltyPercent().call(
      {
        from: account,
      },
      (err, res) => {
        if (err) {
          reject(err);
        }
        resolve(res / 100000);
      }
    );
  });
};

const auctionMap = (auctionId) => {
  return new Promise((resolve, reject) => {
    marketMethods.auctionMap(auctionId).call(
      {
        from: account,
      },
      (err, res) => {
        if (err) {
          reject(err);
        }
        resolve(res.id);
      }
    );
  });
};

const getMinBidFromAuction = (auctionId) => {
  return new Promise((resolve, reject) => {
    marketMethods.getMinBidFromAuction(auctionId).call(
      {
        from: store.getters["wallet/getMarketContract"],
      },
      (err, res) => {
        if (err) {
          reject(err);
        }
        resolve(res);
      }
    );
  });
};

const rateOfAuctionId = (auctionId) => {
  return new Promise((resolve, reject) => {
    marketMethods.rateOfAuctionId(auctionId).call(
      {
        from: store.getters["wallet/getMarketContract"],
      },
      (err, res) => {
        if (err) {
          reject(err);
        }
        resolve(res.id);
      }
    );
  });
};

const sign = (address, dataToSign) => {
  return new Promise((resolve, reject) => {
    window.web3.eth.personal
      .sign(dataToSign, address, "")
      .then((res) => {
        resolve({
          address,
          signature: res,
        });
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

const getGasPrice = () => {
  return new Promise((resolve, reject) => {
    window.web3.eth
      .getGasPrice()
      .then((r) => {
        resolve(r / store.getters["wallet/getMultiplier"]);
      })
      .catch((e) => reject(e));
  });
};

const encodeFunctionCall = (params, abiName, methodName) => {
  let newInterface = new ethers.utils.Interface(abiName);
  return newInterface.encodeFunctionData(methodName, params);
};

const generateDomainData = (name, verifyingContract) => {
  return {
    chainId: `${store.getters["wallet/getChainIdDec"]}`,
    version: "1",
    verifyingContract,
    name,
  };
};

const signTypedDataV4 = (params) => {
  return new Promise((resolve, reject) => {
    window.web3.currentProvider.sendAsync(
      {
        method: "eth_signTypedData_v4",
        params,
      },
      (err, result) => {
        if (result.error) {
          reject();
        }
        resolve(result.result);
      }
    );
  });
};

export default {
  //WRITE CONTRACT

  initWeb3Methods,
  initWalletName,
  changeCostSimpleAuction,
  checkPendingAuction,
  closeAuctionByUser,
  createRateByTimeAuction,
  createToken,
  createSimpleAuction,
  createTimeAuction,
  createTokenAndSimpleAuction,
  createTokenAndTimeAuction,
  buyTokenBySimpleAuction,
  transferFrom,
  changeTimeAuction,
  burn,
  executeForMarket,

  //READ CONTRACT

  auctionMap,
  getMinBidFromAuction,
  rateOfAuctionId,
  _royaltyPercent,

  sign,
  signTypedDataV4,
  getGasPrice,
  generateDomainData,
  encodeFunctionCall,
  formatPrice,
};
