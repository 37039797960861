import Vue from "vue";
import Vuex from "vuex";
import User from "@/store/modules/user";
import Wallet from "@/store/modules/wallet";
import General from "@/store/modules/general";
import Price from "@/store/modules/price";
import Marketplace from "@/store/modules/marketplace";
import Rate from "@/store/modules/rate";
import Auction from "@/store/modules/auction";
import Language from "@/store/modules/language";
import Search from "@/store/modules/search";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    general: General,
    user: User,
    wallet: Wallet,
    price: Price,
    marketplace: Marketplace,
    rate: Rate,
    auction: Auction,
    language: Language,
    search: Search,
  },
});
