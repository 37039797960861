import axios from "axios";
import store from "@/store";
import router from "@/router";

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 500000,
  contentType: "application/json",
});

http.axios = axios;

http.interceptors.request.use(
  (config) => {
    if (config.params && config.params.isPublic) {
      config.params.isPublic = null;
      return config;
    }
    config.params.isPublic = null;
    let token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer_${token}`;
    } else {
      throw new axios.Cancel("USER_CANCEL");
    }
    return config;
  },
  () => {
    store.commit("user/unsetUser");
    if (router.currentRoute.meta.requiresAuth) {
      const lang = localStorage.getItem("lang");
      router.push(lang ? `/${lang}/sign-in` : "/sign-in");
    }
  }
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    let status = error.response?.data.status;
    if (status === 403) {
      store.commit("user/unsetUser");
      if (router.currentRoute.meta.requiresAuth) {
        const lang = localStorage.getItem("lang");
        router.push(lang ? `/${lang}/sign-in` : "/sign-in");
      }
    }
    return Promise.reject(error);
  }
);

export default http;
